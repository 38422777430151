<template>
  <div class="page-container-new">
    <page-loading v-if="state.pageLoading"></page-loading>

    <!-- 등록 이메일 -->
    <div v-if="!state.pageLoading" class="poll-edit-container">
      <page-header
        title="투표 상세 정보"
        :back-button="true"
        :is-back-btn-action="true"
        @back-btn-action="actions.doModal('showPageOutWarning', 'open')"
      >
      </page-header>

      <div class="poll-form">
        <div class="polls-setting">
          <div>
            <!--        제목 설명-->
            <card-basic class="title-desc">
              <div class="header">
                <h4>투표 기본 정보</h4>
                <button-text
                  text-size="s2"
                  text="투표 페이지로 이동"
                  padding="0 14px"
                  text-weight="text"
                  :is-icon="true"
                  icon-position="back"
                  @click="actions.moveToVotePage()"
                >
                  <template #icon>
                    <export-icon fill-color="#0D0D10"></export-icon>
                  </template>
                </button-text>
              </div>
              <div>
                <div class="featured-img-area">
                  <label class="sub-text-s2">대표 이미지</label>
                  <div
                    class="featured-img"
                    :style="`background-image: url(${state.pollForm.featuredImage});`"
                  >
                    <input
                      ref="fileInput"
                      type="file"
                      hidden
                      @change="actions.addAttachment($event)"
                    />
                    <div class="img-upload">
                      <camera-button
                        @click="actions.openFileSelector()"
                      ></camera-button>
                    </div>
                  </div>
                </div>

                <div class="tab-section">
                  <div
                    v-for="(item, index) in localizations"
                    :key="`${item.lang}-tab`"
                  >
                    <button-tab
                      :text="`${item.label}`"
                      :active="item.lang === state.activeLangTab"
                      @action="actions.changeActiveTab(item.lang)"
                    ></button-tab>
                    <text-divider
                      v-if="index !== localizations.length - 1"
                      color="#ECF1F4"
                      height="20px"
                      margin="0 10px"
                    ></text-divider>
                  </div>
                </div>

                <div class="information">
                  <div
                    v-for="(item, index) in localizations"
                    v-show="item.lang === state.activeLangTab"
                    :key="`lang-${index}`"
                  >
                    <input-basic-new
                      class="title"
                      label="투표 주제"
                      placeholder="투표 주제를 자유롭게 입력해주세요"
                      :default-value="
                        state.pollForm.localizations[item.lang]['title']
                      "
                      @updateData="
                        (value) =>
                          actions.updateLocalizationsOfPollForm(
                            item.lang,
                            'title',
                            value
                          )
                      "
                    >
                    </input-basic-new>
                    <div class="desc">
                      <label class="sub-text-s2">투표 설명</label>
                      <MdEditor
                        v-model="
                          state.pollForm.localizations[item.lang].description
                        "
                        language="en-US"
                        :editor-id="`md-editor-${item.lang}-desc`"
                        :toolbars="toolbars"
                        @onUploadImg="
                          (files, callback) => {
                            actions.onUploadImg(files, callback);
                          }
                        "
                      ></MdEditor>
                    </div>

                    <div class="text">
                      <label class="sub-text-s2">투표 안내</label>
                      <MdEditor
                        v-model="state.pollForm.localizations[item.lang].text"
                        language="en-US"
                        :editor-id="`md-editor-${item.lang}-text`"
                        :toolbars="toolbars"
                        @onUploadImg="
                          (files, callback) => {
                            actions.onUploadImg(files, callback);
                          }
                        "
                      ></MdEditor>
                    </div>

                    <div class="text">
                      <label class="sub-text-s2">투표 하단 설명</label>
                      <MdEditor
                        v-model="
                          state.pollForm.localizations[item.lang].content
                        "
                        language="en-US"
                        :editor-id="`md-editor-${item.lang}-content`"
                        :toolbars="toolbars"
                        @onUploadImg="
                          (files, callback) => {
                            actions.onUploadImg(files, callback);
                          }
                        "
                      ></MdEditor>
                    </div>

                    <div class="text">
                      <label class="sub-text-s2">가이드</label>
                      <MdEditor
                        v-model="state.pollForm.localizations[item.lang].guide"
                        language="en-US"
                        :editor-id="`md-editor-${item.lang}-guide`"
                        :toolbars="toolbars"
                        @onUploadImg="
                          (files, callback) => {
                            actions.onUploadImg(files, callback);
                          }
                        "
                      ></MdEditor>
                    </div>
                  </div>
                </div>
              </div>
            </card-basic>
            <!--        상세 설정-->
            <card-basic class="detail">
              <h4>투표 진행 설정</h4>
              <input-select-small
                v-model="state.pollForm.captchaType"
                class="captcha-type"
                label="캡챠 설정"
                :list="captchas"
              ></input-select-small>
              <div class="period">
                <div v-for="item in dateInputs" :key="item.key">
                  <label class="sub-text-s2">{{ item.label }}</label>
                  <input-date-picker
                    :placeholder="item.placeholder"
                    :date="state.pollForm[`${item.key}`]"
                    :error="!!state.errorMessages[`${item.key}`]"
                    format="YYYY-MM-DD HH:mm"
                    @updateDate="
                      (value) => {
                        actions.updateDateValue(item.key, value);
                      }
                    "
                  ></input-date-picker>
                  <p
                    v-if="state.errorMessages[`${item.key}`]"
                    class="date-error sub-text-s3 text-red-50"
                  >
                    {{ state.errorMessages[`${item.key}`] }}
                  </p>
                </div>
              </div>
              <div class="input-box">
                <input-basic-new
                  type="number"
                  label="선택 가능 갯수 (최대 15개)"
                  min="1"
                  max="15"
                  :default-value="state.pollForm.maxCheck"
                  @update-data="
                    (value) => actions.updateInputValue('maxCheck', value)
                  "
                ></input-basic-new>
                <input-basic-new
                  type="number"
                  label="투표 집계 간격 (분)"
                  min="1"
                  :default-value="state.pollForm.voteCountInterval"
                  @update-data="
                    (value) =>
                      actions.updateInputValue('voteCountInterval', value)
                  "
                ></input-basic-new>
                <input-basic-new
                  type="number"
                  label="인당 투표 가능 횟수"
                  min="1"
                  :default-value="state.pollForm.userCheckCount"
                  @update-data="
                    (value) => actions.updateInputValue('userCheckCount', value)
                  "
                ></input-basic-new>
                <input-basic-new
                  type="number"
                  label="IP당 투표 가능 횟수"
                  min="1"
                  :default-value="state.pollForm.ipCheckCount"
                  @update-data="
                    (value) => actions.updateInputValue('ipCheckCount', value)
                  "
                ></input-basic-new>
              </div>
              <div class="check-box">
                <input-checkbox
                  v-for="item in checkInputs"
                  :key="item.key"
                  class="check-box"
                  :label="item.label"
                  :default-value="state.pollForm[`${item.key}`]"
                  @update-data="
                    (checked) => actions.updateCheckValue(item.key, checked)
                  "
                ></input-checkbox>
              </div>
            </card-basic>
            <!--          펀딩 설정-->
            <card-basic class="detail">
              <h4>펀딩 설정</h4>
              <p class="sub-text-s2 text-gray-second">
                목표 달성 수는 로열젬 기준으로 설정해주세요.
              </p>
              <div class="paid-voting">
                <input-basic-new
                  type="number"
                  label="목표 달성 젬 수"
                  sub-label="*펀딩 달성 기준 설정 시, 투표 상세에서 펀딩 달성율 및 총 득표수가 노출됩니다."
                  min="0"
                  :default-value="state.pollForm.voteCountTarget"
                  :error-message="state.errorMessages.voteCountTarget"
                  @update-data="
                    (value) =>
                      actions.updateNumberCount('voteCountTarget', value)
                  "
                ></input-basic-new>
              </div>
            </card-basic>
            <!--          투표권 사용 설정-->
            <card-basic class="detail">
              <h4>투표권 설정</h4>
              <div class="suffrage-box">
                <div class="input-suffrage">
                  <input-checkbox
                    class="check-box"
                    label="로열젬 여부"
                    :default-value="state.pollForm.isSuffrage"
                    @update-data="
                      (checked) =>
                        actions.updateCheckValue('isSuffrage', checked)
                    "
                  ></input-checkbox>
                  <div v-if="state.pollForm.isSuffrage">
                    <!--                로열젬-->
                    <input-count
                      type="number"
                      label="1회 투표 당 필요한 로열젬 수를 입력해주세요."
                      :error-message="state.errorMessages.suffrage"
                      :default-value="state.pollForm.suffrageCount"
                      @update-data="
                        (value) =>
                          actions.updateNumberCount('suffrageCount', value)
                      "
                    ></input-count>
                    <p
                      v-if="suffrageDescription"
                      class="sub-text-s2 text-gray-second"
                    >
                      {{ suffrageDescription.suffrage }}
                    </p>
                  </div>
                </div>
                <div class="input-suffrage">
                  <!--              isFreeSuffrage-->
                  <input-checkbox
                    class="check-box"
                    label="빅젬 여부"
                    :default-value="state.pollForm.isFreeSuffrage"
                    @update-data="
                      (checked) =>
                        actions.updateCheckValue('isFreeSuffrage', checked)
                    "
                  ></input-checkbox>
                  <!--              freeSuffrageCount-->
                  <div v-if="state.pollForm.isFreeSuffrage">
                    <!--                빅젬-->
                    <input-count
                      type="number"
                      label="1회 투표 당 필요한 빅젬 수를 입력해주세요."
                      :step="state.pollForm.isSuffrage ? 20 : 1"
                      :error-message="state.errorMessages.freeSuffrage"
                      :default-value="state.pollForm.freeSuffrageCount"
                      @update-data="
                        (value) =>
                          actions.updateNumberCount('freeSuffrageCount', value)
                      "
                    ></input-count>
                    <p
                      v-if="suffrageDescription"
                      class="sub-text-s2 text-gray-second"
                    >
                      {{ suffrageDescription.freeSuffrage }}
                    </p>
                  </div>
                </div>
              </div>
            </card-basic>
            <!-- 투표 허용 국가 설정 -->
            <card-basic class="geo-fence">
              <div class="header">
                <div>
                  <h4>투표 Geofencing 설정</h4>
                  <button-basic
                    padding="0"
                    bg-color="transparent"
                    text-size="s2"
                    color="#8E1EFF"
                    text="+ 화이트 리스트 설정"
                    @action="actions.doModal('showGeoFence', 'open')"
                  ></button-basic>
                </div>
                <p class="sub-text-s2 text-gray-second">
                  투표 Geofencing 기능을 통해 특정 국가에서만 투표를 진행할 수
                  있습니다. <br />화이트 리스트를 추가하여 허용된 국가를
                  설정하세요. 화이트 리스트가 비어있으면 모든 국가에서 투표를
                  진행할 수 있습니다.
                </p>
              </div>
              <div class="body">
                <div>
                  <div v-if="state.pollForm.geoFences.length > 0">
                    <label class="sub-text-s2">화이트 리스트 국가</label>
                    <ul class="white-list">
                      <li
                        v-for="(item, index) in state.pollForm.geoFences"
                        :key="`white-list-${index}`"
                        class="sub-text-s2 text-gray-second"
                      >
                        {{ item.country.name }}
                      </li>
                    </ul>
                  </div>
                  <p v-else class="sub-text-s2 text-gray-second empty">
                    현재 허용된 국가가 없습니다. 모든 국가에서 투표가
                    가능합니다.
                  </p>
                </div>
              </div>
            </card-basic>
            <div class="button-wrapper">
              <button-basic
                class="update-btn"
                text="수정"
                :is-loading="state.isEditing"
                :disabled="state.isDeleting || state.isEditing"
                @action="actions.editPoll()"
              ></button-basic>
              <div class="danger-zone">
                <h4 class="text-red-50">Danger Zone</h4>
                <div>
                  <p class="sub-text-s2">
                    해당 투표 정보는 모두 삭제되며, 삭제 후에는 복구할 수
                    없어요.
                  </p>
                  <button
                    class="delete-btn sub-title-s1"
                    @click="actions.doModal('showDeleteWaringModal', 'open')"
                  >
                    삭제
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="polls-option">
          <card-basic class="vote-info">
            <div>
              <h4>투표 진행 결과</h4>
            </div>
            <input-basic-new
              :disabled="true"
              class="url"
              label="투표 url"
            ></input-basic-new>
            <div class="update-at">
              <input-basic-new
                label="최근 집계 업데이트 날짜"
                :disabled="true"
                :default-value="state.pollForm.voteCountUpdatedAt"
              ></input-basic-new>
            </div>
            <input-basic-new
              class="vote-count"
              type="number"
              label="총 투표 횟수"
              :disabled="true"
              :default-value="state.pollForm.voteCount"
            ></input-basic-new>
          </card-basic>
          <div class="label">
            <h4>선택지 설정</h4>
            <button-basic
              class="option-add-btn"
              padding="0"
              bg-color="transparent"
              text-size="s2"
              color="#8E1EFF"
              text="+ 후보 추가하기"
              @action="actions.doModal('showPollOptions', 'open')"
            ></button-basic>
          </div>
          <ul v-if="state.pollOptions.length > 0" class="polls-option-list">
            <draggable
              v-model="state.pollOptions"
              item-key="index"
              handle=".handle"
              :disabled="state.isSorting"
              @change="actions.sortPollOptions(state.pollOptions)"
            >
              <template #item="{ element }">
                <li>
                  <poll-candidate-item
                    :poll="element"
                    :draggable="true"
                    :is-sorting="state.isSorting"
                    :vote-count="state.pollForm.voteCount"
                    @open-edit-modal="actions.openPollOptionEditModal(element)"
                    @open-delete-modal="
                      actions.openDeletePollOptionsWarningModal(element)
                    "
                  ></poll-candidate-item>
                </li>
              </template>
            </draggable>
          </ul>
          <empty-list
            v-if="state.pollOptions.length === 0"
            :text="`생성된 선택지가 없어요.<br />선택지를 만들어 팔로워 의견을
              알아보세요.`"
            btn-text="후보 추가하기"
            :is-info-icon="true"
            :is-button="true"
            @btn-action="actions.doModal('showPollOptions', 'open')"
          >
            <template #buttonIcon>
              <arrow-icon class="arrow-icon" stroke-size="normal"></arrow-icon>
            </template>
          </empty-list>
        </div>
      </div>
    </div>
  </div>
  <warning-modal
    v-if="state.showDeleteWaringModal"
    warning-title="해당 투표를 삭제할까요?"
    warning-text="해당 투표 정보는 모두 삭제되며, 삭제 후에는 복구할 수 없어요."
    confirm-text="삭제"
    cancel-text="취소"
    @confirm="actions.deletePoll()"
    @hideModal="actions.doModal('showDeleteWaringModal')"
  ></warning-modal>
  <warning-modal
    v-if="state.showDeletePollOptionsWaringModal"
    warning-title="해당 후보를 삭제할까요?"
    warning-text="해당 후보 정보는 모두 삭제되며, 삭제 후에는 복구할 수 없어요."
    confirm-text="삭제"
    cancel-text="취소"
    @confirm="actions.deletePollOptions()"
    @hideModal="actions.closeDeletePollOptionsWarningModal()"
  ></warning-modal>
  <warning-modal
    v-if="state.showPageOutWarning"
    warning-title="투표 만들기를 취소할까요?"
    warning-text="지금까지 작성한 내용은 저장되지 않아요."
    confirm-text="나가기"
    cancel-text="계속쓰기"
    @confirm="actions.pageOut()"
    @hide-modal="actions.doModal('showPageOutWarning')"
  ></warning-modal>
  <poll-options-create-modal
    v-if="state.showPollOptions"
    :resource-id="state.pollResourceId"
    :poll-options-item="state.pollOptionsItem"
    @hide-modal="actions.closePollOptionsCreateModal()"
    @save-complete="(value) => actions.createPollOptions(value)"
    @update-data="(value) => actions.editPollOptions(value)"
  ></poll-options-create-modal>
  <geo-fence-create-modal
    v-if="state.showGeoFence"
    :white-list-country="state.pollForm.geoFences"
    @hide-modal="actions.doModal('showGeoFence')"
    @save-complete="(value) => actions.createGeoFence(value)"
  ></geo-fence-create-modal>
</template>

<script>
import { useStore } from "vuex";
import {
  reactive,
  onBeforeMount,
  computed,
  ref,
  getCurrentInstance,
  onUnmounted,
} from "vue";
import MdEditor from "md-editor-v3";
import "md-editor-v3/lib/style.css";
import PageHeader from "../../../components/console/headers/PageHeader";
import PageLoading from "../../../components/console/loadings/PageLoading";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import { useRoute, useRouter } from "vue-router";
import InputBasicNew from "@/components/console/inputs/InputBasicNew.vue";
import InputDatePicker from "@/components/console/inputs/InputDatePicker.vue";
import InputCheckbox from "@/components/console/inputs/InputCheckbox.vue";
import CameraButton from "@/pages/console/EntClubs/CameraButton/CameraButton.vue";
import moment from "moment-timezone";
import draggable from "vuedraggable";
import { cloneDeep } from "lodash";
import swal from "@/helper/swal";
import WarningModal from "@/components/console/modals/WarningModal.vue";
import PollOptionsCreateModal from "@/pages/console/PollEdit/PollOptionsCreateModal/PollOptionsCreateModal.vue";
import PollCandidateItem from "@/pages/console/PollEdit/PollICandidateItem/PollICandidateItem.vue";
import InputSelectSmall from "@/components/console/inputs/InputSelectSmall.vue";
import helper from "@/helper";
import {
  filterAndExtractImagePath,
  updateEmptyStringsToNull,
} from "@/helper/poll.js";
import EmptyList from "@/components/console/empties/EmptyList.vue";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import ExportIcon from "@/components/console/icons/ExportIcon.vue";
import GeoFenceCreateModal from "@/pages/console/PollEdit/GeoFenceCreateModal/GeoFenceCreateModal.vue";
import ApiService from "@/api";
import localizations from "@/consts/poll";
import ButtonTab from "@/components/console/buttons/ButtonTab.vue";
import TextDivider from "@/components/console/dividers/TextDivider.vue";
import InputCount from "@/pages/console/PollEdit/InputCount/InputCount.vue";

export default {
  name: "PollEdit",
  components: {
    InputCount,
    TextDivider,
    ButtonTab,
    ButtonText,
    ArrowIcon,
    EmptyList,
    PollCandidateItem,
    PollOptionsCreateModal,
    WarningModal,
    draggable,
    CameraButton,
    InputCheckbox,
    InputDatePicker,
    InputBasicNew,
    ButtonBasic,
    CardBasic,
    PageLoading,
    PageHeader,
    InputSelectSmall,
    ExportIcon,
    MdEditor,
    GeoFenceCreateModal,
  },
  props: {
    poll: {
      type: Object,
      required: true,
    },
    pollResourceId: {
      type: String,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { proxy } = getCurrentInstance();

    const captchas = proxy.$const.captchas.map((captcha) => ({
      value: captcha,
      text: captcha,
    }));

    const toolbars = [
      "bold",
      "underline",
      "italic",
      "-",
      "strikeThrough",
      "title",
      "sub",
      "sup",
      "quote",
      "unorderedList",
      "orderedList",
      "task", // ^2.4.0
      "-",
      "codeRow",
      "code",
      "link",
      "image",
      "-",
      "revoke",
      "next",
      "save",
      "=",
      "pageFullscreen",
      "fullscreen",
      "preview",
      "htmlPreview",
    ];

    const fileInput = ref();

    const state = reactive({
      pageLoading: false,
      pollForm: {
        captchaType: "",
        chat: {},
        description: null,
        featuredImage: proxy.$const.blankImage,
        // locale date
        startAt: "",
        // locale date
        finishAt: "",
        id: null,
        isChat: false,
        ipCheckCount: null,
        isClosed: null,
        isDaily: null,
        isOption: null,
        isRequired: null,
        isShow: true,
        isVerifyCaptcha: false,
        isDeviceCheck: false,
        isShowVoteCount: false,
        isShowBanner: true,
        maxCheck: 1,
        title: null,
        text: null,
        guide: null,
        localizations: {
          ko: {
            title: "",
            description: "",
            text: "",
            content: "",
            guide: "",
            featuredImage: "",
          },
          en: {
            title: "",
            description: "",
            text: "",
            content: "",
            guide: "",
            featuredImage: "",
          },
          ja: {
            title: "",
            description: "",
            text: "",
            content: "",
            guide: "",
            featuredImage: "",
          },
          zh_cn: {
            title: "",
            description: "",
            text: "",
            content: "",
            guide: "",
            featuredImage: "",
          },
          zh_tw: {
            title: "",
            description: "",
            text: "",
            content: "",
            guide: "",
            featuredImage: "",
          },
        },
        // 수정시 서버에서 받지 않는 데이터임.
        pollOptions: [],
        publishedAt: "",
        sortNo: null,
        userCheckCount: 1,
        voteCount: 0,
        voteCountInterval: 5,
        voteCountTarget: 0,
        voteCountUpdatedAt: "",
        isSuffrage: false,
        suffrageCount: 0,
        isFreeSuffrage: false,
        freeSuffrageCount: 0,
        geoFences: [],
      },
      // // utc date
      // startAt: computed(() => {
      //   return state.pollForm.startAt ? state.pollForm.startAt.toJSON() : "";
      // }),
      // // utc date
      // finishAt: computed(() => {
      //   return state.pollForm.finishAt ? state.pollForm.finishAt.toJSON() : "";
      // }),
      pollOptions: [],
      fileUploading: false,
      pollResourceId: null,
      showPollOptions: false,
      showDeleteWaringModal: false,
      showDeletePollOptionsWaringModal: false,
      pollOptionsItem: null,
      showPageOutWarning: false,
      errorMessages: {
        maxCheck: computed(() => {
          return isNumber(state.pollForm.maxCheck) ? "" : "값을 확인해주세요.";
        }),
        voteCountInterval: computed(() => {
          return isNumber(state.pollForm.voteCountInterval)
            ? ""
            : "값을 확인해주세요.";
        }),
        userCheckCount: computed(() => {
          return isNumber(state.pollForm.userCheckCount)
            ? ""
            : "값을 확인해주세요.";
        }),
        ipCheckCount: computed(() => {
          return isNumber(state.pollForm.ipCheckCount)
            ? ""
            : "값을 확인해주세요.";
        }),
        publishedAt: "",
        startAt: "",
        finishAt: "",
        voteCountTarget: "",
        suffrage: "",
        freeSuffrage: "",
      },
      isSorting: false,
      // todo api 통신했는데 실패한 경우 에러메시지와 버튼 로딩 처리 푸는 로직 개선해야함.
      isEditing: false,
      isDeleting: false,
      showGeoFence: false,
      activeLangTab: "ko",
    });

    onBeforeMount(async () => {
      state.pageLoading = true;
      state.pollResourceId = route.params.pollResourceId;
      let payload = { resourceId: state.pollResourceId };
      store.dispatch("polls/getPoll", payload).then(() => {
        const storedPollData = store.getters["polls/getPoll"];

        // Deep merge the localizations
        const updatedLocalizations = Object.keys(
          state.pollForm.localizations
        ).reduce((acc, key) => {
          acc[key] = {
            ...state.pollForm.localizations[key],
            ...storedPollData.localizations[key],
          };
          return acc;
        }, {});

        state.pollForm = {
          ...state.pollForm,
          ...storedPollData,
          localizations: updatedLocalizations,
        };

        // todo null 인 경우 초기 값으로 설정하는 함수 만들어야 함.
        state.pollForm.suffrageCount === null &&
          (state.pollForm.suffrageCount = 0);

        state.pollForm.voteCountTarget === null &&
          (state.pollForm.voteCountTarget = 0);

        // locale 변환
        state.pollForm.startAt = moment(state.pollForm.startAt);
        state.pollForm.finishAt = state.pollForm.finishAt
          ? moment(state.pollForm.finishAt)
          : "";
        state.pollForm.publishedAt = state.pollForm.publishedAt
          ? moment(state.pollForm.publishedAt)
          : "";
        state.pollForm.voteCountUpdatedAt = state.pollForm.voteCountUpdatedAt
          ? helper.dateTimeFormat(state.pollForm.voteCountUpdatedAt)
          : "";

        state.pollOptions = store.getters["polls/getPollOptions"];
        state.pageLoading = false;
      });
    });

    onUnmounted(() => {
      store.commit("polls/resetPoll");
    });

    const suffrageDescription = computed(() => {
      if (state.pollForm.voteCountTarget > 0) {
        return {
          suffrage: `펀딩 달성 젬 수까지 로열젬 기준 ${state.pollForm.voteCountTarget}gem이 필요합니다.`,
          freeSuffrage: `펀딩 달성 젬 수까지 빅젬 기준 ${
            state.pollForm.isSuffrage
              ? state.pollForm.voteCountTarget * 20
              : state.pollForm.voteCountTarget
          }gem이 필요합니다.`,
        };
      }
      return null;
    });

    const goToPolls = () => {
      router.push({ name: "console.polls" });
    };

    const validateMaxCheck = (value) => {
      const numValue = parseFloat(value);
      const maxNum = isNaN(numValue) ? 1 : Math.min(15, Math.max(1, numValue));
      return maxNum;
    };

    const isNumber = (value) => {
      return !isNaN(parseFloat(value)) && isFinite(value);
    };

    const setUpPollOptionsItem = (pollOptionsItem = null) => {
      state.pollOptionsItem = pollOptionsItem;
    };

    const checkInputs = [
      {
        label: "긴급 종료 여부",
        key: "isClosed",
      },
      {
        label: "디바이스 체크 여부",
        key: "isDeviceCheck",
      },
      {
        label: "1일 1투표 여부",
        key: "isDaily",
      },
      {
        label: "캡챠 보안 강화 여부",
        key: "isVerifyCaptcha",
      },
      {
        label: "실시간 채팅 기능",
        key: "isChat",
      },
      {
        label: "브랜드홈 이동 배너 노출 여부",
        key: "isShowBanner",
      },
    ];

    const dateInputs = [
      {
        label: "투표 공개일",
        placeholder: "투표 공개 일시를 선택해주세요",
        key: "publishedAt",
      },
      {
        label: "투표 시작일",
        placeholder: "투표 시작 일시를 선택해주세요",
        key: "startAt",
      },
      {
        label: "투표 종료일",
        placeholder: "투표 종료 일시를 선택해주세요",
        key: "finishAt",
      },
    ];

    const deletePayloadData = (payload, deleteKeyArray) => {
      deleteKeyArray.forEach((item) => {
        delete payload[item];
      });
    };

    const actions = {
      changeActiveTab: (langCode) => {
        state.activeLangTab = langCode;
      },
      updateLocalizationsOfPollForm: (lang, formKey, value) => {
        state.pollForm.localizations[lang][formKey] = value;
      },
      updateDateValue: (label, value) => {
        state.pollForm[`${label}`] = moment(value);
      },
      openFileSelector: () => {
        fileInput.value.click();
      },
      updateInputValue: (label, value) => {
        if (label === "maxCheck") {
          state.pollForm.maxCheck = validateMaxCheck(value);
        } else {
          state.pollForm[`${label}`] = value;
        }
      },
      updateCheckValue: (label, booleanVal) => {
        state.pollForm[`${label}`] = booleanVal;
      },
      updateNumberCount: (key, number) => {
        let fromStrToNum = Number(number);
        state.pollForm[key] = fromStrToNum;
        if (key === "voteCountTarget" && fromStrToNum > 0) {
          state.pollForm.isSuffrage = true;
        }
        if (key === "suffrageCount" && state.pollForm.isFreeSuffrage) {
          state.pollForm.freeSuffrageCount = fromStrToNum * 20;
        }
        if (key === "freeSuffrageCount" && state.pollForm.isSuffrage) {
          state.pollForm.suffrageCount = state.pollForm.freeSuffrageCount / 20;
        }
      },
      sortPollOptions: (pollOptions) => {
        let pollOptionsResourceIds = [];
        pollOptions.forEach((pollOption) => {
          pollOptionsResourceIds.push(pollOption.resourceId);
        });

        let payload = {
          pollResourceId: state.pollResourceId,
          data: { resourceIds: pollOptionsResourceIds },
        };

        state.isSorting = true;
        store.dispatch("polls/postPollOptionsResort", payload).then(() => {
          state.isSorting = false;
          swal.editCompleteToast();
        });
      },
      addAttachment: (e) => {
        state.fileUploading = true;
        const files = e.target.files || e.dataTransfer.files;
        const formData = new FormData();
        formData.append("file", files[0]);
        let payload = {
          pollResourceId: state.pollResourceId,
          data: formData,
        };
        store
          .dispatch("polls/postPollFeaturedImage", payload)
          .then((response) => {
            const result = response.data;
            if (result.success) {
              state.pollForm.featuredImage = result.data.featuredImage;
              state.fileUploading = false;
            }
          });
      },
      editPoll: async () => {
        if (!state.pollForm.publishedAt) {
          state.errorMessages.publishedAt = "공개일을 설정해주세요.";
          return;
        }

        // if (state.pollForm.publishedAt.diff(moment()) <= 0) {
        //   console.log(state.pollForm.publishedAt.diff(state.pollForm.startAt));
        //   state.errorMessages.publishedAt = "현재 시간 이후로 설정해주세요.";
        //   return;
        // }
        state.errorMessages.publishedAt = "";

        if (!state.pollForm.finishAt) {
          state.errorMessages.finishAt = "종료일을 설정해주세요.";
          return;
        }

        if (state.pollForm.finishAt.diff(state.pollForm.publishedAt) <= 0) {
          state.errorMessages.finishAt = "종료일은 공개일 보다 빠를 수 없어요.";
          return;
        }

        if (state.pollForm.finishAt.diff(state.pollForm.startAt) <= 0) {
          state.errorMessages.finishAt = "종료일이 시작일 보다 빠를 수 없어요.";
          return;
        }

        state.errorMessages.finishAt = "";

        if (state.pollForm.voteCountTarget < 0) {
          state.errorMessages.voteCountTarget =
            "목표 달성 젬 수를 설정해주세요.";
          return;
        }

        if (!Number.isInteger(state.pollForm.voteCountTarget)) {
          state.errorMessages.voteCountTarget = "소수점은 입력이 불가능해요.";
          return;
        }

        if (state.pollForm.isSuffrage) {
          if (!Number.isInteger(state.pollForm.suffrageCount)) {
            state.errorMessages.suffrage = "소수점은 입력이 불가능해요.";
            return;
          }

          if (state.pollForm.suffrageCount <= 0) {
            state.errorMessages.suffrage = "로열젬 수를 설정해주세요.";
            return;
          }
        }

        if (state.pollForm.isFreeSuffrage) {
          if (!Number.isInteger(state.pollForm.freeSuffrageCount)) {
            state.errorMessages.freeSuffrage = "소수점은 입력이 불가능해요.";
            return;
          }

          if (state.pollForm.freeSuffrageCount <= 0) {
            state.errorMessages.freeSuffrage = "빅젬 수를 설정해주세요.";
            return;
          }
        }

        if (state.pollForm.isSuffrage && state.pollForm.isFreeSuffrage) {
          if (
            state.pollForm.suffrageCount !==
            state.pollForm.freeSuffrageCount / 20
          ) {
            state.errorMessages.suffrage =
              "로열젬과 빅젬을 동시에 설정하실 경우, 비율은 1:20로 자동 설정 됩니다.";
            state.errorMessages.freeSuffrage =
              "로열젬과 빅젬을 동시에 설정하실 경우, 비율은 1:20로 자동 설정 됩니다.";
            return;
          }
        }

        // 에러 메시지 초기화.
        state.errorMessages.voteCountTarget = "";
        state.errorMessages.suffrage = "";
        state.errorMessages.freeSuffrage = "";

        if (!state.pollForm.isSuffrage) {
          state.pollForm.suffrageCount = 0;
        }

        let data = cloneDeep(state.pollForm);

        // delete data.voteCount;
        // delete data.voteCountUpdatedAt;
        // geo-fences 설정 api는 별도로 만듬.
        // delete data.geoFences;
        // delete data.pollOptions;

        for (const key in data.localizations) {
          const localization = data.localizations[key];
          updateEmptyStringsToNull(localization, [
            "title",
            "description",
            "text",
            "guide",
            "content",
          ]);
        }

        // utc로 변환
        data.startAt = data.startAt.toJSON();
        data.finishAt = data.finishAt.toJSON();
        data.publishedAt = data.publishedAt.toJSON();

        data.featuredImage = filterAndExtractImagePath(data.featuredImage);

        // delete data.title;
        // delete data.description;

        // delete data.isSuffrage;
        // delete data.suffrageCount;

        deletePayloadData(data, [
          "voteCount",
          "voteCountUpdatedAt",
          "geoFences",
          "pollOptions",
          "title",
          "description",
        ]);

        let payload = {
          pollResourceId: state.pollResourceId,
          data: data,
        };

        state.isEditing = true;

        store.dispatch("polls/putPoll", payload).then(() => {
          state.pollForm = {
            ...state.pollForm,
            ...store.getters["polls/getPoll"],
          };

          // locale 변환
          state.pollForm.startAt = moment(state.pollForm.startAt);
          state.pollForm.finishAt = state.pollForm.finishAt
            ? moment(state.pollForm.finishAt)
            : "";
          state.pollForm.publishedAt = state.pollForm.publishedAt
            ? moment(state.pollForm.publishedAt)
            : "";
          state.pollForm.voteCountUpdatedAt = state.pollForm.voteCountUpdatedAt
            ? helper.dateTimeFormat(state.pollForm.voteCountUpdatedAt)
            : "";

          state.pollOptions = store.getters["polls/getPollOptions"];
          swal.editCompleteToast();
          state.isEditing = false;
        });
      },
      deletePoll: () => {
        let payload = {
          pollResourceId: state.pollResourceId,
        };

        state.isDeleting = true;
        store.dispatch("polls/deletePoll", payload).then((res) => {
          if (res.data.success) {
            swal.deleteCompleteToast();
            goToPolls();
          } else {
            if (res.error) {
              swal.messageErrorAlert(res.error.message);
            }
            swal.messageErrorAlert(`티켓에 연결된 투표는 삭제가 불가능합니다.`);
          }
        });
      },
      deletePollOptions: () => {
        let payload = {
          pollResourceId: state.pollResourceId,
          pollOptionResourceId: state.pollOptionsItem.resourceId,
        };
        store.dispatch("polls/deletePollOptions", payload).then(() => {
          setUpPollOptionsItem();
          state.showDeletePollOptionsWaringModal = false;
          swal.deleteCompleteToast();
        });
      },
      doModal: (key, method = "close") => {
        state[`${key}`] = method === "open";
      },
      openDeletePollOptionsWarningModal: (pollOptionsItem) => {
        setUpPollOptionsItem(pollOptionsItem);
        state.showDeletePollOptionsWaringModal = true;
      },
      closeDeletePollOptionsWarningModal: () => {
        setUpPollOptionsItem();
        state.showDeletePollOptionsWaringModal = false;
      },
      closePollOptionsCreateModal: () => {
        setUpPollOptionsItem();
        state.showPollOptions = false;
      },
      openPollOptionEditModal: (pollOptionsItem) => {
        setUpPollOptionsItem(pollOptionsItem);
        state.showPollOptions = true;
      },
      createPollOptions: (pollOptionForm) => {
        const data = pollOptionForm;
        for (const key in data.localizations) {
          const localization = data.localizations[key];
          updateEmptyStringsToNull(localization, ["title"]);
        }

        let payload = {
          pollResourceId: state.pollResourceId,
          data: data,
        };

        store.dispatch("polls/postPollOptions", payload).then(() => {
          state.pollOptions = store.getters["polls/getPollOptions"];
          swal.createCompleteToast();
          state.showPollOptions = false;
        });
      },
      editPollOptions: (pollOptionForm) => {
        let data = cloneDeep(pollOptionForm);

        for (const key in data.localizations) {
          const localization = data.localizations[key];
          updateEmptyStringsToNull(localization, ["title"]);
        }

        data.featuredImage = filterAndExtractImagePath(data.featuredImage);

        delete data.title;

        let payload = {
          pollResourceId: state.pollResourceId,
          pollOptionResourceId: state.pollOptionsItem.resourceId,
          data: data,
        };
        store.dispatch("polls/putPollOptions", payload).then(() => {
          swal.editCompleteToast();
          setUpPollOptionsItem();
          state.showPollOptions = false;
        });
      },
      createGeoFence: async (countries) => {
        let payload = {
          pollResourceId: state.pollResourceId,
          data: {
            isWhiteList: true,
            countries: countries,
          },
        };

        try {
          await ApiService.postPollGeofence(
            payload.pollResourceId,
            payload.data
          );
          const result = await ApiService.getPoll(payload.pollResourceId);
          const data = result.data.data;
          state.pollForm.geoFences = data.geoFences;
          state.showGeoFence = false;
          swal.editCompleteToast();
        } catch (error) {
          console.error(error);
        }
      },
      pageOut: () => {
        state.showPageOutWarning = false;
        if (router.options.history.state.back === null) {
          goToPolls();
        } else {
          router.back();
        }
      },
      moveToVotePage: () => {
        const voteUrl = `${process.env.VUE_APP_VOTE_URL}/votes/${state.pollResourceId}`;
        window.open(voteUrl, "_blank");
      },
      onUploadImg: async (files, callback) => {
        const res = await Promise.all(
          files.map((file) => {
            return new Promise((rev, rej) => {
              const formData = new FormData();
              formData.append("file", file);
              ApiService.postAttachment(formData)
                .then((res) => rev(res))
                .catch((error) => {
                  rej(error);
                  swal.errorToast(error.message);
                });
            });
          })
        );

        callback(res.map((item) => item.data.data.url));
      },
    };

    return {
      state,
      actions,
      fileInput,
      captchas,
      toolbars,
      localizations,
      checkInputs,
      dateInputs,
      suffrageDescription,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
